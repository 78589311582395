<template>
  <el-dialog
    :modal-append-to-body="false"
    :visible.sync="visible"
    :title="$t('m.new.chat_message_text')"
    top="10px"
    width="900px"
    @closed="handleClosed"
  >
    <div>
      <div
        v-loading="loading"
        id="msgbox"
        :style="{'height': height}"
        class="msg-main-box"
      >
        <div v-for="item in list" :key="item.id">
          <!-- 发送方为自己 -->
          <div
            v-if="item.senderId !== userId"
            class="chat-item self-chat-item"
          >
            <!-- 单位名称 -->
            <div class="nickName">
              {{ userInfo.businessname }}
            </div>
            <!-- 文字内容 -->
            <div class="text-item">
              <img class="arrow-icon" src="./images/im_arrow_right.png">
              <div class="dialog-content-right text-content">
                {{ item.message }}
              </div>
            </div>
            <!-- 时间 -->
            <div class="time">{{ item.time }}</div>
          </div>
          <!-- 发送方为对方 -->
          <div
            v-else
            class="chat-item other-chat-item"
          >
            <div class="nickName">{{ $languageType === '1' ? '对方' : 'Opposite' }}</div>
            <div class="text-item">
              <img class="arrow-icon" src="./images/im_arrow_left.png">
              <div class="dialog-content-left text-content">
                {{ item.message }}
              </div>
            </div>
            <div class="time">{{ item.time }}</div>
          </div>
        </div>
      </div>
      <!-- 输入框 与 发送按钮 -->
      <div class="input-box">
        <el-input
          v-model="message"
          :autosize="{minRows: 3, maxRows: 5}"
          :placeholder="$t('m.new.please_inter_text_ph')"
          type="textarea"
          @keyup.enter.native="handleEnterSend"
        />
        <div style="margin-top: 20px; text-align: right;">
          <el-button
            v-if="showDockBtn"
            type="text"
            size="small"
            style="font-size: 14px; margin-right: 10px;"
            @click="handleGoDocking"
          >{{ $t('m.new.docking_appointment_text') }}</el-button>
          <el-button
            :disabled="!message.length"
            type="primary"
            size="small"
            style="font-size: 14px;"
            @click="handleSend"
          >{{ $t('m.send_text') }}</el-button>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
//
import { normalMsgHistory, normalSendMsg } from '@/api/activity'
// 立即预约报名
import { getApplyIdByUser } from '@/api/cceec/activity'

export default {
  props: {
    // 对方id
    userId: {
      type: Number,
      required: true
    },
    //
    contentId: {
      type: Number,
      required: true
    },
    // 来源 [1：需求、2：供给]
    messageType: {
      type: Number,
      default: 1
    },
    // 是否显示立即预约按钮
    showDockBtn: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      visible: false,
      loading: false,
      message: '',
      list: [],
      issend: false,
      interval: null
    }
  },
  computed: {
    height() {
      return 'calc(100vh - 300px)'
    },
    userInfo() {
      return this.$store.state.user.userInfo
    }
  },
  watch: {
    userId(val) {
      if (val) {
        this.getMsgList()
        this.starInterval()
      } else {
        this.list.length = 0
        if (this.interval) {
          clearInterval(this.interval)
        }
      }
    }
  },
  created() {
    this.getMsgList()
    this.starInterval()
  },
  destroyed() {
    if (this.interval) {
      clearInterval(this.interval)
    }
  },
  methods: {
    init() {
      this.visible = true
    },
    handleClosed() {
      this.$emit('closeIMChat')
    },
    //  消息列表
    getMsgList() {
      const data = {
        activityId: process.env.VUE_APP_NORMAL_ACTIVITY_ID, // 常态化活动id
        receiverId: this.userId,
        contentId: this.contentId,
        messageType: this.messageType
      }
      normalMsgHistory(data).then(res => {
        console.log(res)
        this.list = res.data ? res.data : []
        setTimeout(() => {
          this.updateScroll()
        }, 0)
      })
    },
    //  回车发送
    handleEnterSend() {
      if (this.message.length > 1) {
        if (!this.issend) {
          this.issend = true
          const data = {
            activityId: process.env.VUE_APP_NORMAL_ACTIVITY_ID, // 常态化活动id
            message: this.message,
            receiverId: this.userId,
            contentId: this.contentId,
            messageType: this.messageType
          }
          normalSendMsg(data).then(() => {
            this.issend = false
            this.message = ''
            this.getMsgList()
            this.$message({
              message: 'Send a success',
              type: 'success'
            })
          }).catch(() => {
            this.issend = false
          })
        }
      }
    },
    handleSend() {
      if (!this.issend) {
        this.issend = true
        const data = {
          activityId: process.env.VUE_APP_NORMAL_ACTIVITY_ID,
          message: this.message,
          receiverId: this.userId,
          contentId: this.contentId,
          messageType: this.messageType
        }
        normalSendMsg(data).then(() => {
          this.issend = false
          this.message = ''
          this.getMsgList()
          this.$message({
            message: 'Send a success',
            type: 'success'
          })
        }).catch(() => {
          this.issend = false
        })
      }
    },
    starInterval() {
      if (this.interval) {
        clearInterval()
      }
      this.interval = setInterval(() => {
        this.getMsgList()
      }, 15000)
    },
    updateScroll() {
      const element = document.getElementById('msgbox')
      if (element) {
        element.scrollTop = element.scrollHeight
      }
    },
    // 对接预约
    handleGoDocking() {
      // 获取自己的报名id,如果没有报名服务端自动报名
      const data = {
        userId: this.userInfo.id
      }
      getApplyIdByUser(data).then(() => {
        // 获取对方的报名id
        this.handleGetOppositeApplyId()
      })
    },
    // 获取对方的报名id
    handleGetOppositeApplyId() {
      getApplyIdByUser({ userId: this.userId }).then(()=> {
        const userId = this.userId
        this.$router.push({
          path: '/admin/home/normal',
          query: {
            activityId: process.env.VUE_APP_NORMAL_ACTIVITY_ID,
            userId: userId
          }
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  /deep/ .el-dialog__header {
    background-color: #E8E9EB;
  }
  .msg-main-box {
    padding: 10px 20px;
    overflow: hidden;
    overflow-y: scroll;
    /*border: 1px solid red;*/
  }
  .chat-item {
    margin-top: 5px;
    /*border: 1px solid yellow;*/
    .nickName {
      color: #333333;
    }
    .text-item {
      position: relative;
      display: flex;
      margin: 6px 0;
      /*text-align: justify;*/
      /*border: 1px solid green;*/
      .text-content {
        position: relative;
        padding: 10px 16px;
        max-width: 70%;
        height: auto;
        color: #ffffff;
        font-size: 14px;
        line-height: 18px;
        border-radius: 7px;
        // 换行
        word-wrap: break-word;
        // 单词换行不截断
        word-break: keep-all;
      }
    }
    .time {
      margin-top: 5px;
      color: #666666;
      font-size: 12px;
    }
  }

  .self-chat-item {
    .nickName {
      padding-right: 20px;
      text-align: right;
    }
    .text-item {
      display: flex;
      flex-direction: row-reverse;
      .dialog-content-right {
        margin-right: 16px;
        background-color: #409EFF;
      }
    }
    .time {
      padding-right: 20px;
      text-align: right;
    }
  }

  .other-chat-item {
    .nickName {
      padding-left: 20px;
    }
    .text-item {
      .dialog-content-left {
        margin-left: 16px;
        background-color: #9EAFC1;
      }
    }
    .time {
      padding-left: 20px;
    }
  }

  .arrow-icon {
    position: absolute;
    bottom: 2px;
    width: auto;
    height: 24px;
  }

  .input-box {
    margin-top: 10px;
    padding-top: 20px;
    border-top: 1px solid #cccccc;
  }
</style>
