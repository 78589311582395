<template>
  <div class="app-container">
    <div class="our_prent">
      <!-- 中东欧合作需求 -->
      <span v-if="type === '1'">
        {{ $t('m.navbar.ceec_cooperate_text') }}
      </span>
      <!-- 中方合作需求 -->
      <span v-else-if="type === '2'">
        {{ $t('m.navbar.china_cooperate_text') }}
      </span>
      <!-- 项目合作案例 -->
      <span v-else-if="type === '3'">
        {{ $t('m.navbar.project_cooperate_text') }}
      </span>
      <!-- 创新机构介绍 -->
      <span v-else-if="type === '4'">
        {{ $t('m.navbar.innovation_institution_text') }}
      </span>
      <!-- 中东欧技术供给 -->
      <span v-else-if="type === '5'">
        {{ $t('m.navbar.ceec_tech_supply_text') }}
      </span>
      <!-- 中方技术供给 -->
      <span v-else-if="type === '6'">
        {{ $t('m.navbar.china_tech_supply_text') }}
      </span>
    </div>
    <table style="width: 1200px;">
      <tbody>
      <tr>
        <td style="vertical-align: top;">
          <div class="z_contts">
            <div v-loading="loading" class="isreal_leftcont">
              <div v-if="obj">
                <!-- 标题 -->
                <div style="text-align: center;">
                  <!-- 技术供给 -->
                  <h3 v-if="type === '5' || type === '6'">
                    {{ obj.supplyName }}
                  </h3>
                  <!-- 合作需求 -->
                  <h3 v-else-if="type === '1' || type === '2'">
                    {{ obj.demandName }}
                  </h3>
                  <!-- 项目合作案例 -->
                  <h3 v-else-if="type === '3'">
                    {{ obj.title }}
                  </h3>
                  <!-- 创新机构介绍 -->
                  <h3 v-else>{{ obj.orgName }}</h3>
                </div>
                <!-- 附加属性 -->
                <div v-if="type !== '3'" class="fgh">
                  <div>
                    <!-- 国家 -->
                    <div :class="[$language === 'cn' ? 'item_cn' : 'item_en']">
                      <div :class="[$language === 'cn' ?  'span_cn' : 'span_en']">
                        {{ $language === 'cn' ? '国家' : 'Country:' }}
                        <span
                          v-if="$language === 'cn'"
                          style="display: inline-block; padding-left: 100%;"
                        />
                      </div>
                      <span v-if="$language === 'cn'">：</span>
                      <span :style="{marginLeft: this.$language === 'cn' ? '0' : '3px'}">
                          {{ formatCountry(obj.nation, countryOptions, this.$language) }}
                        </span>
                    </div>
                    <!-- 发布时间 -->
                    <div :class="[$language === 'cn' ? 'item_cn' : 'item_en']">
                      <div :class="[$language === 'cn' ?  'span_cn' : 'span_en']">
                        {{ $language === 'cn' ? '发布时间' : 'Release time:' }}
                        <span
                          v-if="$language === 'cn'"
                          style="display: inline-block; padding-left: 100%;" />
                      </div>
                      <span v-if="$language === 'cn'">：</span>
                      <span :style="{marginLeft: this.$language === 'cn' ? '0' : '3px'}">
                          {{ obj.publishTime }}
                        </span>
                    </div>
                    <!-- 行业分类 -->
                    <div :class="[$language === 'cn' ? 'item_cn' : 'item_en']">
                      <div :class="[$language === 'cn' ?  'span_cn' : 'span_en']">
                        {{ $language === 'cn' ? '行业分类' : 'Industry:' }}
                        <span style="display: inline-block; padding-left: 100%;" />
                      </div>
                      <span v-if="$language === 'cn'">：</span>
                      <!-- 合作需求 -->
                      <span v-if="type === '1' || type === '2'" :style="{marginLeft: this.$language === 'cn' ? '0' : '3px'}">
                          {{ formatIndustry(obj.demandType)}}
                        </span>
                      <!-- 创新机构介绍 -->
                      <span v-if="type === '4'" :style="{marginLeft: this.$language === 'cn' ? '0' : '3px'}">
                          {{ formatIndustry(obj.orgType) }}
                        </span>
                      <!-- 技术供给 -->
                      <span v-if="type === '5' || type === '6'" :style="{marginLeft: this.$language === 'cn' ? '0' : '3px'}">
                          {{ formatIndustry(obj.supplyType) }}
                        </span>
                    </div>
                  </div>
                </div>
                <!-- 内容 -->
                <div style="margin-top: 40px;">
                  <!-- 合作需求 -->
                  <div v-if="type === '1' || type === '2'">
                    <div v-if="obj.desc && obj.desc !== '<p><br></p>'">
                      <strong>
                        {{ $language !== 'cn' ? 'Cooperation Requirements Description:' : '合作需求简介：' }}
                      </strong>
                      <div class="rich-box" v-html="obj.desc" />
                    </div>
                    <div v-if="obj.cooperationIntention && obj.cooperationIntention !== '<p><br></p>'">
                      <strong>
                        {{ $language !== 'cn' ? 'Ideal Way of Cooperation:' : '合作方式：' }}
                      </strong>
                      <div class="rich-box" v-html="obj.cooperationIntention" />
                    </div>
                    <div v-if="false && obj.others && obj.others !== '<p><br></p>'">
                      <strong>
                        {{ $language !== 'cn' ? 'Other:' : '其他：' }}
                      </strong>
                      <div class="rich-box" v-html="obj.others" />
                    </div>
                  </div>
                  <!-- 技术供给 -->
                  <div v-else-if="type === '5' || type === '6'">
                    <div v-if="obj.description && obj.description !== '<p><br></p>'">
                      <strong>
                        {{ $language !== 'cn' ? 'Technical Introduction:' : '技术简介：' }}
                      </strong>
                      <div class="rich-box" v-html="obj.description" />
                    </div>
                    <div v-if="obj.innovation && obj.innovation !== '<p><br></p>'">
                      <strong>
                        {{ $language !== 'cn' ? 'Innovation points:' : '创新点：' }}
                      </strong>
                      <div class="rich-box" v-html="obj.innovation" />
                    </div>
                    <div v-if="obj.cooperationIntention && obj.cooperationIntention !== '<p><br></p>'">
                      <strong>
                        {{ $language !== 'cn' ? 'Cooperation:' : '合作意向：' }}
                      </strong>
                      <div class="rich-box" v-html="obj.cooperationIntention" />
                    </div>
                    <div v-if="obj.others && obj.others !== '<p><br></p>'">
                      <strong>
                        {{ $language !== 'cn' ? 'Other:' : '其他：' }}
                      </strong>
                      <div class="rich-box" v-html="obj.others" />
                    </div>
                  </div>
                  <!-- 项目合作案例 -->
                  <div v-else-if="type === '3'" v-html="obj.body" />
                  <!-- 创新机构介绍 -->
                  <div v-else v-html="obj.description" />
                </div>
                <!-- 是否可以发起常态化对接 -->
                <div v-if="showOperation" class="operation-box">
                  <el-button
                    type="primary"
                    size="small"
                    @click="handleGoDocking"
                  >{{ $t('m.new.docking_appointment_text') }}</el-button>
                  <el-button
                    plain
                    type="primary"
                    size="small"
                    @click="handleSendIMChat"
                  >{{ $t('m.new.send_message_text') }}</el-button>
                </div>
              </div>
            </div>
          </div>
        </td>
        <td style="vertical-align: top;">
          <menu-box :active-index="type" />
        </td>
      </tr>
      </tbody>
    </table>
    <!-- 发送消息 -->
    <im-chat-box
      v-if="obj && showChat"
      ref="imChatDialog"
      :user-id="Number(obj.userId)"
      :content-id="Number(obj.linkId)"
      :message-type="(type === '1' || type === '2') ? 1 : 2"
      @closeIMChat="showChat = false"
    />
  </div>
</template>

<script>
//
import MenuBox from '../components/menubox'
import ImChatBox from '@/components/Normal/imChatBox' // 发送消息

import { getApplyIdByUser } from '@/api/cceec/activity'
import {
  supplyDetails,
  demandDetails,
  caseDetails,
  orgDetails
} from '@/api/cceec/cooperate'
import { countryList, industryList, secIndustryList } from '@/api/cceec/constant'
import {
  formatCountry,
  formatIndustryName,
  getExecAStrs,
  getExecBStrs
} from '@/utils/tool'

export default {
  name: 'CooperateDetails',
  components: {
    MenuBox,
    ImChatBox
  },
  filters: {
    formatName(val) {
      return val.replace(/,/g, '')
    }
  },
  data() {
    return {
      loading: false,
      type: '1',
      id: null,
      obj: null,
      showChat: false,
      countryOptions: [],
      industryOptions: [],
      industryOptions2: []
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.user.userInfo
    },
    showOperation() {
      if (this.userInfo) {
        return false
        if (this.$route.query.source === 'activity') {
          return false
        }
        if (this.userInfo.country === '1') {
          // 中国企业，只能在中东欧合作需求中发起预约
          return (this.type === '1') && (this.obj && this.obj.userId && this.obj.userId !== this.userInfo.id)
        } else {
          // 外方企业，只能在中方合作需求中发起预约
          return (this.type === '2') && (this.obj && this.obj.userId && this.obj.userId !== this.userInfo.id)
        }
        // return (this.type === '1' || this.type === '2' || this.type === '5' || this.type === '6') && (this.obj && this.obj.userId && this.obj.userId !== this.userInfo.id)
      } else {
        return false
      }
    }
  },
  created() {
    if (this.$route.query.type) {
      this.type = this.$route.query.type
    }
    if (this.$route.query.id) {
      this.id = this.$route.query.id
      this.getDetails()
    }
  },
  methods: {
    formatCountry,
    getDetails() {
      if (this.type === '5' || this.type === '6') {
        // 中东欧技术供给 / 中方技术供给
        this.getCountryList()
        this.getIndustryList()
        this.getSupplyDetails()
      } else if (this.type === '1' || this.type === '2') {
        // 中东欧合作需求 / 中方合作需求
        this.getCountryList()
        this.getIndustryList()
        this.getDemandDetails()
      } else if (this.type === '3') {
        // 项目合作案例
        this.getCaseDetails()
      } else {
        // 创新机构介绍
        this.getCountryList()
        this.getIndustryList()
        this.getOrgDetails()
      }
    },
    //  中东欧合作需求
    getSupplyDetails() {
      this.loading = true
      const data = {
        languageType: this.$languageType
      }
      supplyDetails(this.id, data).then(res => {
        this.loading = false
        console.log(res)
        this.obj = res.data
      }).catch(() => {
        this.loading = false
      })
    },
    //  中方合作需求
    getDemandDetails() {
      this.loading = true
      const data = {
        languageType: this.$languageType
      }
      demandDetails(this.id, data).then(res => {
        this.loading = false
        console.log(res)
        this.obj = res.data
      }).catch(() => {
        this.loading = false
      })
    },
    //  项目合作需求
    getCaseDetails() {
      this.loading = true
      const data = {
        languageType: this.$languageType
      }
      caseDetails(this.id, data).then(res => {
        this.loading = false
        console.log(res)
        this.obj = res.data
      }).catch(() => {
        this.loading = false
      })
    },
    // 创新机构介绍
    getOrgDetails() {
      this.loading = true
      const data = {
        languageType: this.$languageType
      }
      orgDetails(this.id, data).then(res => {
        this.loading = false
        console.log(res)
        this.obj = res.data
      }).catch(() => {
        this.loading = false
      })
    },
    //  行业
    formatIndustry(val) {
      const arr = val.split(',')
      let result = ''
      arr.forEach(str => {
        if (str) {
          const a = Number(getExecAStrs(str))
          const nameA = formatIndustryName(a, this.industryOptions, this.$language)
          const b = Number(getExecBStrs(str))
          const nameB = formatIndustryName(b, this.industryOptions2, this.$language)
          result = result + nameA + '>' + nameB + ','
        }
      })
      if (result.length) {
        result = result.substr(0, result.length - 1)
      }
      // return 'New Energy and Energy Efficiency>Biomass Energy, New Energy and Energy Efficiency>Biomass Energy, New Energy and Energy Efficiency>Biomass Energy'
      return result
    },
    //  国家
    getCountryList() {
      countryList().then(res => {
        console.log(res)
        this.countryOptions = res.data
      })
    },
    //  行业
    getIndustryList() {
      //  一级行业
      const data = {
        level: 1
      }
      industryList(data).then(res => {
        console.log(res)
        this.industryOptions = res.data
      })
      //  二级行业
      secIndustryList().then(res => {
        console.log(res)
        this.industryOptions2 = res.data
      })
    },
    // 发送消息
    handleSendIMChat() {
      this.showChat = true
      this.$nextTick(() => {
        this.$refs.imChatDialog.init()
      })
    },
    // 对接预约
    handleGoDocking() {
      // 获取自己的报名id,如果没有报名服务端自动报名
      const data = {
        userId: this.userInfo.id
      }
      console.log(data)
      getApplyIdByUser(data).then(() => {
        // 获取对方的报名id
        this.handleGetOppositeApplyId()
      })
    },
    // 获取对方的报名id
    handleGetOppositeApplyId() {
      getApplyIdByUser({ userId: this.obj.userId }).then(()=> {
        // console.log(res)
        // const applyId = res.data
        const userId = this.obj.userId
        const query = '/#/admin/home/normal?activityId=' + process.env.VUE_APP_NORMAL_ACTIVITY_ID + '&userId=' + userId
        if (this.$language === 'cn') {
          // 当前为中文网站
          const url = process.env.VUE_APP_PERSONAL_CN_WEB_URL + query
          window.open(url, '_blank')
        } else {
          // 当前为英文网站
          const url = process.env.VUE_APP_PERSONAL_EN_WEB_URL + query
          window.open(url, '_blank')
        }

        // const routeUrl = this.$router.resolve({
        //   path: '/admin/home/normal',
        //   query: {
        //     activityId: process.env.VUE_APP_NORMAL_ACTIVITY_ID,
        //     userId: userId
        //   }
        // })
        // window.open(routeUrl.href, '_blank')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  table {
    tr {
      margin: 0;
      padding: 0;
      height: 60px;
    }
    td {
      padding: 0;
    }
  }
  .z_contts {
    width: 950px;
    /*min-height: 500px;*/
    /*line-height: 30px;*/
    /*border: 1px solid red;*/
    .wrapper-content {
      margin-top: 10px;
      padding-top: 0px !important;
    }
  }
  .isreal_leftcont {
    width: 950px;
    min-height: 300px;
    font-size: 14px !important;
    h3 {
      font-weight: normal;
      font-size: 20px !important;
    }
    .fgh {
      margin-top: 20px;
      color: #000;
      font-size: 14px;
      line-height: 20px;
      .item_cn {
        display: flex;
        margin-top: 10px;
        .span_cn {
          display: inline-block;
          width: 56px;
          min-width: 56px;
          height: 20px;
          line-height: 20px;
          text-align: justify;
          /*text-justify: inter-ideograph;*/
          /*text-align-last: justify;!* ie9*!*/
          -moz-text-align-last: justify; /* 使文本在最后一行两端对齐 */
          -webkit-text-align-last: justify;
        }
        .justify_fix{display:inline-block; width:100%; height:0; overflow:hidden;}
      }
      .item_en {
        display: flex;
        margin-top: 10px;
        .span_en {
          padding-right: 3px;
        }
      }
    }
    .rich-box {
      position: relative;
      top: -5px;
      /*background-color: red;*/
    }
  }
  .operation-box {
    margin-top: 50px;
  }
</style>
