import request from '@/utils/request'

//  根据活动id获取详情
export function activityDetails(params) {
  return request({
    url: '/api/lico/acTivity/activityInfo',
    method: 'get',
    params: params
  })
}

//  上次报名资料
export function eventApplyInfo(path) {
  return request({
    url: '/api/lico/acTivity/lastApply/' + path,
    method: 'get'
  })
}

//  本次活动
export function eventSelfApplyInfo(params) {
  return request({
    url: '/api/lico/acTivity/selfApply',
    method: 'get',
    params: params
  })
}

//  活动列表接口
export function eventList(params) {
  return request({
    url: '/api/lico/acTivity/activityList',
    method: 'get',
    params: params
  })
}

//  活动报名
export function activityApply(data) {
  return request({
    url: '/api/lico/acTivity/activityApply',
    method: 'post',
    data: data
  })
}

//  组织列表
export function orgList(params) {
  return request({
    url: '/api/lico/acTivity/orgList',
    method: 'get',
    params: params
  })
}

//  报名详情
export function viewApply(params) {
  return request({
    url: '/api/lico/acTivity/viewApply',
    method: 'get',
    params: params
  })
}

//  预约时间列表
export function scheduleList(params) {
  return request({
    url: '/api/lico/acTivity/scheduleList',
    method: 'get',
    params: params
  })
}

//  发起预约
export function sendInvitation(params) {
  return request({
    url: '/api/lico/acTivity/order',
    method: 'post',
    params: params
  })
}

//  处理请求
export function handleInvitation(data) {
  return request({
    url: '/api/lico/acTivity/handle',
    method: 'post',
    data: data
  })
}

//  发送消息
export function sendMsg(data) {
  return request({
    url: '/api/lico/acTivity/sendMsg',
    method: 'post',
    data: data
  })
}

//  消息列表
export function msgList(params) {
  return request({
    url: '/api/lico/acTivity/viewMsg',
    method: 'get',
    params: params
  })
}

//  我的活动列表
export function myScheduleList(params) {
  return request({
    url: '/api/lico/acTivity/mySchedule',
    method: 'get',
    params: params
  })
}

//  我的预约列表
export function myDockingInfo(params) {
  return request({
    url: '/api/lico/acTivity/myDockingInfo',
    method: 'get',
    params: params
  })
}

//  前往视频会议
export function videoJump() {
  return request({
    url: '/video/jump',
    method: 'get'
  })
}

// 请求企业报名活动时，勾选的需求与供给信息
export function applyDemandAndSupplyView(params) {
  return request({
    url: '/api/lico/acTivity/applyDemandAndSupplyView',
    method: 'get',
    params: params
  })
}

// 获取活动对接日列表
export function activityDates(params) {
  return request({
    url: '/api/lico/acTivity/dockingDate',
    method: 'get',
    params: params
  })
}

/**
 * 常态化
 */
// 发送聊天消息
export function normalSendMsg(data) {
  return request({
    url: '/api/lico/acMember/sendDockChat',
    method: 'post',
    data: data
  })
}
// 获取聊天记录
export function normalMsgHistory(params) {
  return request({
    url: '/api/lico/acMember/viewDockChat',
    method: 'get',
    params: params
  })
}
// 对接信息列表（个人中心）
export function normalApplyList(params) {
  return request({
    url: '/api/lico/acMember/dockChatList',
    method: 'get',
    params: params
  })
}

// 首页对接日程
export function dockingScheduleList() {
  return request({
    url: '/api/cceec/scheduleList',
    method: 'get'
  })
}

// 跳转
export  function meetingJump() {
  return request({
    url: '/api/video/jump',
    method: 'get'
  })
}
