import request from '@/utils/request'

//  发送消息
export function sendMsg(data) {
  return request({
    url: '/api/lico/acTivity/sendMsg',
    method: 'post',
    data: data
  })
}

//  消息列表
export function msgList(params) {
  return request({
    url: '/api/lico/acTivity/viewMsg',
    method: 'get',
    params: params
  })
}

// 根据 userId 获取报名id
export function getApplyIdByUser(params) {
  return request({
    url: '/api/lico/acTivity/getApplyIdByUser',
    method: 'get',
    params: params
  })
}
